import { createRouter, createWebHistory } from 'vue-router'
//import App from '../App.vue'

import LoginItem from '../components/LoginItem.vue'
import SecureItem from '../components/SecureItem.vue'
import RegisterItem from '../components/RegisterItem.vue'

const routes = [
  /*{
    path: '/',    
    //component: App
    redirect: '/products',
    //component: () => import( '../views/HomeView.vue')
  },*/
  {
    path: '/tst',    
    redirect: '/'
  },
  {
    path: '/paytransfer',
    name: 'oplata',
    component: () => import( '../views/PayTransfer.vue')
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/PayTransfer.vue'  // '../components/DeliveryTransfer.vue'
  )},
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/MessageItem.vue'  
  ),
},
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/PolicyItem.vue'  
  ),

   
  }
  ,
  {
      path: '/login',
      name: 'login',
      component: LoginItem
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterItem
    },
    {
      path: '/secure',
      name: 'secure',
      component: SecureItem,
      meta: { 
        requiresAuth: true
      }
    },
  {
    path: '/product/:category/:idProduct',
    name: 'product',
    component: () => import( '../views/ProductItem.vue')
  },
  {
    path: '/payform/:OrderId',
    name: 'payform',
    component: () => import('../components/PayOrder.vue')
  },
  /*
  {
    path: '/product_new/:idProduct',
    name: 'product_new',
    component: () => import( '../views/ProductItem_new.vue')
  }
  , */
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductsAllView.vue')
  },
  {
    path: '/tst/products',
    redirect: '/products',
    
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import('../views/CartView.vue')
  },
  {
    path: "/cart2",
    name: "cart2",
    component: () => import('../views/CartView2.vue')
  },
  {
    path: "/PayOrder",
    name: "PayOrder",
    component: () => import('../components/PayOrder.vue')
  },
  {
    path: "/tst/cart",
    redirect: '/cart'
  },
    {
    path: "/orderadm",
    name: "Zakaz",
    component: () => import('../components/OrderAdm.vue')
  
  },
{
    path: "/home",
    name: "Home",
    component: () => import('../views/HomeView.vue')
  },
  {
    path: "/productedit",
    name: "uploadFile",
    component: () => import('../views//productEdit.vue')
  },
  { path: "/:catchAll(.*)", component: () => import('../views//PageNotFound.vue')

   }

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",  
})


/*
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (this.store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})*/
export default router
