import { createStore } from "vuex";
//import Vue from 'vue'
//import Vuex from 'vuex'
//import axios from 'axios'

function updateLocalStorage(cart) {
  localStorage.setItem('cart', JSON.stringify(cart))
}
function updateLocalStorage2(delivery) {
  localStorage.setItem('delivery', JSON.stringify(delivery))
 }
 
export default createStore({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
  
    cart: [],
    //order: [],
    order: {
      orderid: '',
      ordernum: '',
      orderdate: '',
      orderclient: '',
      orderphone: '',
      orderemail: '',
      orderaddress: '',
      orderremark: '',
      },
      delivery: {
        chosenPost: '',
        addresPost: '',
        pricePost: '',
        timePost: '',
  
      },
    AddressDelivery: null,
    },
  getters: {
    
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  saleAction: state => state.saleaction,
    getInputValue(state) {
      return state.inputValue;
    },
    productQuantity: (state) => (ProductDetails) => {
      const item = state.cart.find(i => i.id === ProductDetails.id)
      //console.log(item);
      if (item) return item.quantity
      else return 0
    },
    productQuantity1: (state) => (productid) => {
      const item = state.cart.find(i => i.id === productid)
      //console.log(item);
      if (item) return item.quantity
      else return 0
    },
    cartItems: (state) => {
      updateLocalStorage(state.cart);
      return state.cart
    },
    orderItem: (state) => {

        return state.order;
      },
      GetDeliveryAddress: state => {
        updateLocalStorage2(state.delivery);
        return state.delivery;
      },

      GetNewAddress: state => {
        return state.AddressDelivery;
      },
    


    },
    

  actions: {
    /*
  login({commit}, user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios({url: 'http://localhost:3000/login', data: user, method: 'POST' })
      .then(resp => {
        const token = resp.data.token
        const user = resp.data.user
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = token
        commit('auth_success', token, user)
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
      })
    })
  },
register({commit}, user){
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios({url: 'http://localhost:3000/register', data: user, method: 'POST' })
    .then(resp => {
      const token = resp.data.token
      const user = resp.data.user
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = token
      commit('auth_success', token, user)
      resolve(resp)
    })
    .catch(err => {
      commit('auth_error', err)
      localStorage.removeItem('token')
      reject(err)
    })
  })
 },logout({commit}){
  return new Promise((resolve) => {
    var err = null;
    commit('logout',err)
    localStorage.removeItem('token')
    delete axios.defaults.headers.common['Authorization']
    resolve()
  
  })
 },
*/

    addToOrder(contex, payload) {
        contex.commit('addOrder', payload)
    },
    addToDelivery(contex, payload) {
      contex.commit('SetDeliveryAddress', payload)
    }
},
  mutations: {
     auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, token, user){
    state.status = 'success'
    state.token = token
    state.user = user
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = ''
    state.token = ''
  },
    addOrder(state, payload) {
      state.order = payload
  },
    setInputValue(state, payload) {
      state.inputValue = payload;
    },
    addToCart(state, product) {
      let item = state.cart.find(i => i.id === product.id)
      if (item) {
        item.quantity++
      } else {
        state.cart.push({ ...product, quantity: 1 })
      }

      updateLocalStorage(state.cart)
    },
    removeFromCart(state, product) {
      let item = state.cart.find(i => i.id === product.id)

      if (item) {
        if (item.quantity > 1) {
          item.quantity--
        } else {
          state.cart = state.cart.filter(i => i.id !== product.id)
        }
      }

      updateLocalStorage(state.cart)
    },
    addToCart1(state, productid) {
      let item = state.cart.find(i => i.id === productid)
      if (item) {
        item.quantity++
      } else {
        state.cart.push({ ...productid, quantity: 1 })
      }

      updateLocalStorage(state.cart)
    },
    removeFromCart1(state, productid) {
      let item = state.cart.find(i => i.id === productid)

      if (item) {
        if (item.quantity > 1) {
          item.quantity--
        } else {
          state.cart = state.cart.filter(i => i.id !== productid)
        }
      }

      updateLocalStorage(state.cart)
    },

    
    updateCartFromLocalStorage(state) {
      const cart = localStorage.getItem('cart')
      if (cart) {
        state.cart = JSON.parse(cart)
      }
    },
    SetDeliveryAddress(state, payload) {
      state.delivery = payload;
    },

    SetNewAddress(state, payload) {
      state.AddressDelivery = payload;
    },
  /*  
    addToOrder(state, order) {
      //console.log(state);
      //console.log(order);
      //console.log(state.order);
      let itemOrder = state.order.find(i => i.id === order.id)
      if (itemOrder) {
        state.order.push({ itemOrder })
      }

      updateLocalStorage2(state.order)
    },
*/
    updateObj: ({ obj }, payload) => Object.assign(obj, payload),
  },
},
);
