//import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap'
import router from './router'
import store from './store'
//import Axios from 'axios'
//import VueAxios from 'vue-axios'
import realImg from 'vue-real-img'
//import { $, jquery } from 'jquery';
//import VueExtpandableImage from 'vue-expandable-image'
//import VueMeta from 'vue-meta'



//import Axios from 'axios'
//Vue.prototype.$http = Axios;
//const token = localStorage.getItem('token')
/*if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}*/

//import 'https://cdn.jsdelivr.net/npm/@cdek-it/widget@3'



//apikey: '1f44cca5-bd59-41b7-8821-f2706df429b1'

window.$ = window.jQuery = require('jquery');
createApp(App).use(store).use(router).use('bootstrap').use(realImg).mount('#app');





