<template>
  <div>
    <section
      id="HomePage"
      style="max-height: v-bind(mheight); background-color: white"
    >
      <NavigationBar />
      <div class="row col-12" style="align-items: center">
        <div class="col-4" style="text-align: right">
          <img src="./assets/images/phone.png" style="width: 32px" />
        </div>
        <div class="col-7" style="text-align: left">
          <span
            style="
              font: 'Ubuntu Mono';
              font-size: 14px;
              font-weight: 600;
              text-align: center;
              padding-left: 0px;
              color: red;
              line-height: 20%;
            "
            >+7 909 753 00 03</span><br/>
            <span style="padding-left: 5px;font-size: 12px;
            text-align: left;
              color: red;
              line-height: 20%;">Пн-Пт с 9:00 до 18:00</span
          >
        </div>
      </div>
      <router-view />
      <span v-if="isLoggedIn"> | <a @click="logout">Logout</a></span>
      <div
        class="row"
        style="
          align-items: left;
          align-content: left;
          text-align: left;
          padding-left: 20px;
          padding-top: 10px;
          margin: 0px;
        "
      >
        <table>
          <tr>
            <td style="width: 50px;">
              <img src="./assets/images/phone.png" style="width: 32px" />
            </td>
            <td>
              <span
            style="
              font: 'Ubuntu Mono';
              font-size: 14px;
              font-weight: 600;
              text-align: center;
              padding-left: 0px;
              color: red;
              line-height: 20%;
            "
            >+7962 004 18 90 - по вопросам сотрудничества</span><br/>
            <span style="padding-left: 5px; font-size: 12px;
            text-align: left;
              color: red;
              line-height: 20%;">Пн-Пт с 9:00 до 18:00</span
          > </td>
          </tr>
          <tr>
            <td  style="width: 50px;">
              <img src="./assets/images/email.png" />
            </td>

            <td>
              <span
                style="
                  font: 'Ubuntu Mono';
                  font-size: 12px;
                  font-weight: 500;
                  text-align: left;
                  padding-left: 5px;
                  padding: 0px;
                  margin: 0px;
                  color: red;
                ">e-mail:</span>
                <span style="
                font-size: 14px;
                  font-weight: 600;
                  text-align: left;
                  padding-left: 5px;
                  color: red;
                  ">teimur_baits@mail.ru</span
              >
            </td>
          </tr>
          <!--
          <tr>
            <td  style="width: 50px;">
              <img
                src="./assets/images/support.png"
                alt="Если вы замектили ошибку - напищите нам!"
              />
            </td>

            <td>
              <span
                style="
                  font: 'Ubuntu Mono';
                  font-size: 12px;
                  font-weight: 600;
                  text-align: left;
                  padding-left: 5px;
                  padding: 0px;
                  margin: 0px;
                "
              >
                <a href="mailto:helpdesk@teimur-baits.ru">Техподдержка</a></span
              >
            </td>
          </tr>
          -->
          <tr>
            <td>

            </td>
<td><span style="
                font-size: 14px;
                  font-weight: 600;
                  text-align: left;
                  line-height: 4px;
                  ">
  Мы в социальных сетях:
  </span>
</td>

          </tr>
          <tr>
            <td></td>
            <td>
              <ul class="h__social" style="padding-left: 0px;
                  text-align: left;">
                <li>
          <a href="https://api.whatsapp.com/send?phone=79620041890">
            <img src="../static/whatsapp_icon.png"  style="width:32px;"
          /></a>
        </li>
                <li>
          <a href="https://t.me/Teimur_Mikhail">
            <img src="../static/telegram_icon.png"  style="width:32px;"
          /></a>
        </li>
        
      </ul>
            </td>
          </tr>
          <tr>
            <td>
              
            </td>
            <td>
             <span  style="
                font-size: 14px;
                  font-weight: 600;
                  text-align: left;
                  line-height: 4px;
                  
                  ">Подпишитесь!</span>
            </td>
          </tr>
          <tr>
            <td>

            </td>
            <td>
              <span
               style="font-size: 12px; font-weight: 300; line-height: 4px;">
                Получайте скидки! Будьте в курсе акций и новинок нашего магазина
              </span>
            </td>
          </tr>
          
          <tr>
            <td></td>
            <td>
              <ul class="h__social" style="padding-left: 0px;
                  text-align: left;">
                <li>
          <a href="https://t.me/Teimur_Baits">
            <img src="../static/telegram_icon.png"  style="width:32px;"
          /></a>
        </li>
        <li>
          <a href="https://vk.com/teimur_baits">
            <img src="../static/vk_icon.png"   style="width:32px;"
          /></a>
        </li>
        
      </ul>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <!---<div class="hello">
    <div class="login">
      <form @submit.prevent="login">
        <h1>Войти на сайт</h1>
        <label for="username">Имя пользователя</label>
        <input type="text" v-model="username" name="username">
        <label for="password">Пароль</label>
        <input type="text" v-model="password" name="password">
        <button>Войти</button>
      </form>
    </div>
  </div>
-->
      
    </section>
    <!--
    <section id="qrcodes">
      <div class="row col-12 my-2">
        <div class="col-4">
          <img
            src="../public/qrtg.webp"
            alt="Заходите на сайт"
            style="width: 100px"
          />
        </div>
        <div class="col-4">
          <img
            src="../public/qrvk.webp"
            alt="Заходите в VK"
            style="width: 100px"
          />
        </div>
        <div class="col-4">
          <img
            src="../public/qrw.webp"
            alt="Заходите в WhatsApp"
            style="width: 100px"
          />
        </div>
      </div>
    </section> -->
    <div class="row">
      <span>&copy; 2017-2024 Интернет-магазин "Teimur-Baits"</span>
    </div>
  </div>
</template>

<script>
//import { ajax } from "jquery";
import NavigationBar from "./components/Navigation.vue";
import "bootstrap/dist/css/bootstrap.css";
//import Axios from "axios";
//import Vue from 'vue';
//import  ProductItem  from './components/ProductItem.vue'
//import LoginPage from "./components/LoginPage";

export default {
  name: "App",
  components: {
    NavigationBar,
  },

  // Компонент продукта
  data() {
    return {
      //  items: [],
      mheight: "900px",
      response: null,
      products1: [],
      proddata: null,
      pageNumber: 0, // по умолчанию 0
    };
  },
  props: {
    listData: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created: function () {
    /*  Axios.interceptors.response.use(undefined, (err) => {
      return new Promise((resolve, reject) => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout")
          
          //console.log(resolve);
          reject;
        }
        throw err;
      });
    });
*/
    //const $http = Axios.create();
    /*  Axios.interceptors.response.use((response) => {
      return response
    }, (err) => {
      // Unauthorised, log out
      if (err.response && err.response.status === 401) {
        this.logout()
      }
      return Promise.reject(err)
    })
    Vue.prototype.$http.interceptors = Axios;
    const token = localStorage.getItem('token')
    if (token) {
      //  Vue.prototype.
      Axios.defaults.headers.common['Authorization'] = token
    }
*/
  },

  mounted: async function () {
    this.$store.commit("updateCartFromLocalStorage");

  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style>
@font-face {
  font-family: "UrbanSlavic";
  src: url("@/assets/fonts/UrbanSlavic.otf");
}
#app {
  font-family: "Ubuntu Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: white scale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  margin-top: 0px;
  align-items: center;
  width: 800px;
  max-width: 100%;
  height: 30px;
  padding: auto;
  margin: auto;
}
.container {
  max-width: 800 px;
}
.rub {
  line-height: 5px;
  width: 0.4em;
  border-bottom: 1px solid #000;
  display: inline-block;
}

.h__social {
  margin-top: 0px;
  display: flex;
  -ms-flex-wrap: wrap;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  list-style: none;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 32px;
}
.h__social li {
  margin: 0 0px;
  padding-right: 5px;
  height: 32px;
}

.h__social a {
  display: block;
  height: 25px;
  width: 25px;
  line-height: 1;
}

/*
Использован стандартный конвертер SVG to Base 64.
Иконки установлены в прозрачный квадрат 30х30px с закруглением углов в 4px.
При hover зливка квадрата fill меняется на заданную
*/
/*
.instagram {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(731 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-731 -8910)' fill='%233f729b'/%3E%3Cg transform='translate(-13.998 42)'%3E%3Crect width='22' height='22' transform='translate(-713.001 -8948)' fill='none'/%3E%3Cpath d='M10.781,7.49a5.528,5.528,0,1,0,5.528,5.528A5.519,5.519,0,0,0,10.781,7.49Zm0,9.122a3.594,3.594,0,1,1,3.594-3.594,3.6,3.6,0,0,1-3.594,3.594Zm7.044-9.348a1.289,1.289,0,1,1-1.289-1.289A1.286,1.286,0,0,1,17.824,7.264Zm3.661,1.309a6.381,6.381,0,0,0-1.742-4.518,6.423,6.423,0,0,0-4.518-1.742c-1.78-.1-7.116-.1-8.9,0A6.414,6.414,0,0,0,1.812,4.05,6.4,6.4,0,0,0,.071,8.568c-.1,1.78-.1,7.116,0,8.9a6.381,6.381,0,0,0,1.742,4.518A6.431,6.431,0,0,0,6.33,23.724c1.78.1,7.116.1,8.9,0a6.381,6.381,0,0,0,4.518-1.742,6.423,6.423,0,0,0,1.742-4.518c.1-1.78.1-7.111,0-8.891Zm-2.3,10.8a3.639,3.639,0,0,1-2.05,2.05c-1.419.563-4.787.433-6.356.433s-4.941.125-6.356-.433a3.639,3.639,0,0,1-2.05-2.05c-.563-1.419-.433-4.787-.433-6.356s-.125-4.941.433-6.356a3.639,3.639,0,0,1,2.05-2.05c1.419-.563,4.787-.433,6.356-.433s4.941-.125,6.356.433a3.639,3.639,0,0,1,2.05,2.05c.563,1.419.433,4.787.433,6.356S19.749,17.96,19.186,19.375Z' transform='translate(-712.997 -8950.237)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.instagram:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(731 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-731 -8910)' fill='%233f729b'/%3E%3Cg transform='translate(-13.998 42)'%3E%3Crect width='22' height='22' transform='translate(-713.001 -8948)' fill='none'/%3E%3Cpath d='M10.781,7.49a5.528,5.528,0,1,0,5.528,5.528A5.519,5.519,0,0,0,10.781,7.49Zm0,9.122a3.594,3.594,0,1,1,3.594-3.594,3.6,3.6,0,0,1-3.594,3.594Zm7.044-9.348a1.289,1.289,0,1,1-1.289-1.289A1.286,1.286,0,0,1,17.824,7.264Zm3.661,1.309a6.381,6.381,0,0,0-1.742-4.518,6.423,6.423,0,0,0-4.518-1.742c-1.78-.1-7.116-.1-8.9,0A6.414,6.414,0,0,0,1.812,4.05,6.4,6.4,0,0,0,.071,8.568c-.1,1.78-.1,7.116,0,8.9a6.381,6.381,0,0,0,1.742,4.518A6.431,6.431,0,0,0,6.33,23.724c1.78.1,7.116.1,8.9,0a6.381,6.381,0,0,0,4.518-1.742,6.423,6.423,0,0,0,1.742-4.518c.1-1.78.1-7.111,0-8.891Zm-2.3,10.8a3.639,3.639,0,0,1-2.05,2.05c-1.419.563-4.787.433-6.356.433s-4.941.125-6.356-.433a3.639,3.639,0,0,1-2.05-2.05c-.563-1.419-.433-4.787-.433-6.356s-.125-4.941.433-6.356a3.639,3.639,0,0,1,2.05-2.05c1.419-.563,4.787-.433,6.356-.433s4.941-.125,6.356.433a3.639,3.639,0,0,1,2.05,2.05c.563,1.419.433,4.787.433,6.356S19.749,17.96,19.186,19.375Z' transform='translate(-712.997 -8950.237)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.vk {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(590 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-590 -8910)' fill='%232b587a'/%3E%3Cpath d='M24.762,7.717c.165-.557,0-.967-.793-.967H21.344a1.127,1.127,0,0,0-1.141.744,21.989,21.989,0,0,1-3.226,5.369c-.61.61-.891.806-1.225.806-.165,0-.419-.2-.419-.753v-5.2c0-.668-.187-.967-.74-.967H10.468a.633.633,0,0,0-.668.6c0,.633.945.78,1.043,2.562v3.867c0,.847-.151,1-.486,1-.891,0-3.057-3.27-4.34-7.013C5.759,7.044,5.5,6.75,4.832,6.75H2.208c-.749,0-.9.352-.9.744,0,.7.891,4.148,4.148,8.711,2.17,3.114,5.226,4.8,8.007,4.8,1.671,0,1.876-.374,1.876-1.02,0-2.976-.151-3.257.686-3.257.388,0,1.056.2,2.615,1.7,1.782,1.782,2.076,2.58,3.074,2.58h2.624c.749,0,1.127-.374.909-1.114-.5-1.555-3.872-4.754-4.023-4.968-.388-.5-.276-.722,0-1.167,0,0,3.208-4.513,3.538-6.042Z' transform='translate(-588.308 -8908.879)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.vk:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(590 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-590 -8910)' fill='%232b587a'/%3E%3Cpath d='M24.762,7.717c.165-.557,0-.967-.793-.967H21.344a1.127,1.127,0,0,0-1.141.744,21.989,21.989,0,0,1-3.226,5.369c-.61.61-.891.806-1.225.806-.165,0-.419-.2-.419-.753v-5.2c0-.668-.187-.967-.74-.967H10.468a.633.633,0,0,0-.668.6c0,.633.945.78,1.043,2.562v3.867c0,.847-.151,1-.486,1-.891,0-3.057-3.27-4.34-7.013C5.759,7.044,5.5,6.75,4.832,6.75H2.208c-.749,0-.9.352-.9.744,0,.7.891,4.148,4.148,8.711,2.17,3.114,5.226,4.8,8.007,4.8,1.671,0,1.876-.374,1.876-1.02,0-2.976-.151-3.257.686-3.257.388,0,1.056.2,2.615,1.7,1.782,1.782,2.076,2.58,3.074,2.58h2.624c.749,0,1.127-.374.909-1.114-.5-1.555-3.872-4.754-4.023-4.968-.388-.5-.276-.722,0-1.167,0,0,3.208-4.513,3.538-6.042Z' transform='translate(-588.308 -8908.879)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E"); }

.youtube {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(695 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-695 -8910)' fill='none'/%3E%3Cg transform='translate(-6 42)'%3E%3Crect width='22' height='22' transform='translate(-685 -8948)' fill='none'/%3E%3Cpath d='M22.164,6.872a2.71,2.71,0,0,0-1.907-1.919C18.576,4.5,11.832,4.5,11.832,4.5s-6.743,0-8.425.454A2.71,2.71,0,0,0,1.5,6.872,28.425,28.425,0,0,0,1.05,12.1,28.425,28.425,0,0,0,1.5,17.321a2.669,2.669,0,0,0,1.907,1.888c1.682.454,8.425.454,8.425.454s6.743,0,8.425-.454a2.669,2.669,0,0,0,1.907-1.888,28.425,28.425,0,0,0,.451-5.224A28.425,28.425,0,0,0,22.164,6.872ZM9.627,15.3V8.89L15.263,12.1,9.627,15.3Z' transform='translate(-686.05 -8949.3)' fill='%23000'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.youtube:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(695 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-695 -8910)' fill='%23c4302b'/%3E%3Cg transform='translate(-6 42)'%3E%3Crect width='22' height='22' transform='translate(-685 -8948)' fill='none'/%3E%3Cpath d='M22.164,6.872a2.71,2.71,0,0,0-1.907-1.919C18.576,4.5,11.832,4.5,11.832,4.5s-6.743,0-8.425.454A2.71,2.71,0,0,0,1.5,6.872,28.425,28.425,0,0,0,1.05,12.1,28.425,28.425,0,0,0,1.5,17.321a2.669,2.669,0,0,0,1.907,1.888c1.682.454,8.425.454,8.425.454s6.743,0,8.425-.454a2.669,2.669,0,0,0,1.907-1.888,28.425,28.425,0,0,0,.451-5.224A28.425,28.425,0,0,0,22.164,6.872ZM9.627,15.3V8.89L15.263,12.1,9.627,15.3Z' transform='translate(-686.05 -8949.3)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.twitter {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(625 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-625 -8910)' fill='none'/%3E%3Cg transform='translate(9.134 42)'%3E%3Crect width='22' height='22' transform='translate(-630.134 -8948)' fill='none'/%3E%3Cpath d='M19.6,7.8c.014.194.014.388.014.582A12.649,12.649,0,0,1,6.874,21.12,12.65,12.65,0,0,1,0,19.111a9.261,9.261,0,0,0,1.081.055,8.965,8.965,0,0,0,5.557-1.912,4.484,4.484,0,0,1-4.185-3.1,5.645,5.645,0,0,0,.845.069,4.734,4.734,0,0,0,1.178-.152A4.477,4.477,0,0,1,.887,9.673V9.617a4.508,4.508,0,0,0,2.023.568A4.483,4.483,0,0,1,1.524,4.2a12.724,12.724,0,0,0,9.23,4.684,5.053,5.053,0,0,1-.111-1.026,4.481,4.481,0,0,1,7.747-3.063,8.813,8.813,0,0,0,2.841-1.081A4.464,4.464,0,0,1,19.264,6.18a8.974,8.974,0,0,0,2.578-.693A9.623,9.623,0,0,1,19.6,7.8Z' transform='translate(-629.975 -8949.315)' fill='%23000'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.twitter:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(625 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-625 -8910)' fill='%233f729b'/%3E%3Cg transform='translate(9.134 42)'%3E%3Crect width='22' height='22' transform='translate(-630.134 -8948)' fill='none'/%3E%3Cpath d='M19.6,7.8c.014.194.014.388.014.582A12.649,12.649,0,0,1,6.874,21.12,12.65,12.65,0,0,1,0,19.111a9.261,9.261,0,0,0,1.081.055,8.965,8.965,0,0,0,5.557-1.912,4.484,4.484,0,0,1-4.185-3.1,5.645,5.645,0,0,0,.845.069,4.734,4.734,0,0,0,1.178-.152A4.477,4.477,0,0,1,.887,9.673V9.617a4.508,4.508,0,0,0,2.023.568A4.483,4.483,0,0,1,1.524,4.2a12.724,12.724,0,0,0,9.23,4.684,5.053,5.053,0,0,1-.111-1.026,4.481,4.481,0,0,1,7.747-3.063,8.813,8.813,0,0,0,2.841-1.081A4.464,4.464,0,0,1,19.264,6.18a8.974,8.974,0,0,0,2.578-.693A9.623,9.623,0,0,1,19.6,7.8Z' transform='translate(-629.975 -8949.315)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.whatsapp {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(555 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-555 -8910)' fill='%23ee802f'/%3E%3Cg transform='translate(24.378 43.278)'%3E%3Crect width='19' height='19' transform='translate(-574 -8948)' fill='none'/%3E%3Cpath d='M0,16.664a2.465,2.465,0,0,1,.75-1.809,2.465,2.465,0,0,1,1.809-.75,2.465,2.465,0,0,1,1.809.75,2.567,2.567,0,0,1,0,3.627,2.481,2.481,0,0,1-1.809.741,2.465,2.465,0,0,1-1.809-.75A2.465,2.465,0,0,1,0,16.664Zm0-6.446V6.543a12.437,12.437,0,0,1,6.369,1.7A12.61,12.61,0,0,1,11,12.873a12.437,12.437,0,0,1,1.7,6.369H9.006a8.678,8.678,0,0,0-2.636-6.369A8.687,8.687,0,0,0,0,10.218ZM0,3.695V0A18.715,18.715,0,0,1,7.466,1.53,19.419,19.419,0,0,1,13.6,5.638a19.419,19.419,0,0,1,4.108,6.138,18.715,18.715,0,0,1,1.53,7.466H15.548A15.241,15.241,0,0,0,14.317,13.2,15.5,15.5,0,0,0,11,8.236a15.725,15.725,0,0,0-4.965-3.31A15.172,15.172,0,0,0,0,3.695Z' transform='translate(-573.999 -8947.799)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.whatsapp:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(555 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-555 -8910)' fill='%23ee802f'/%3E%3Cg transform='translate(24.378 43.278)'%3E%3Crect width='19' height='19' transform='translate(-574 -8948)' fill='none'/%3E%3Cpath d='M0,16.664a2.465,2.465,0,0,1,.75-1.809,2.465,2.465,0,0,1,1.809-.75,2.465,2.465,0,0,1,1.809.75,2.567,2.567,0,0,1,0,3.627,2.481,2.481,0,0,1-1.809.741,2.465,2.465,0,0,1-1.809-.75A2.465,2.465,0,0,1,0,16.664Zm0-6.446V6.543a12.437,12.437,0,0,1,6.369,1.7A12.61,12.61,0,0,1,11,12.873a12.437,12.437,0,0,1,1.7,6.369H9.006a8.678,8.678,0,0,0-2.636-6.369A8.687,8.687,0,0,0,0,10.218ZM0,3.695V0A18.715,18.715,0,0,1,7.466,1.53,19.419,19.419,0,0,1,13.6,5.638a19.419,19.419,0,0,1,4.108,6.138,18.715,18.715,0,0,1,1.53,7.466H15.548A15.241,15.241,0,0,0,14.317,13.2,15.5,15.5,0,0,0,11,8.236a15.725,15.725,0,0,0-4.965-3.31A15.172,15.172,0,0,0,0,3.695Z' transform='translate(-573.999 -8947.799)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.ok {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(660 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-660 -8910)' fill='none'/%3E%3Cg transform='translate(2.5 43.436)'%3E%3Crect width='19' height='19' transform='translate(-657 -8947.871)' fill='none'/%3E%3Cpath d='M10.474,12.475a8.231,8.231,0,0,1-3.361,1l.781.769,2.85,2.85c1.042,1.068-.654,2.738-1.707,1.707-.713-.725-1.759-1.77-2.85-2.861L3.337,18.8c-1.053,1.027-2.749-.657-1.7-1.707.725-.725,1.759-1.77,2.85-2.85l.769-.769a8.193,8.193,0,0,1-3.384-1C.659,11.687.125,11.227.6,10.267c.276-.545,1.035-1,2.039-.213a6.059,6.059,0,0,0,3.544,1.079,6.059,6.059,0,0,0,3.544-1.079c1-.788,1.759-.332,2.039.213.463.96-.071,1.419-1.289,2.207ZM1.331,4.844A4.844,4.844,0,1,1,6.176,9.677,4.848,4.848,0,0,1,1.331,4.844Zm2.465,0a2.379,2.379,0,1,0,2.379-2.39A2.383,2.383,0,0,0,3.8,4.844Z' transform='translate(-653.613 -8948)' fill='%23000'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.ok:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(660 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-660 -8910)' fill='%23f58220'/%3E%3Cg transform='translate(2.5 43.436)'%3E%3Crect width='19' height='19' transform='translate(-657 -8947.871)' fill='none'/%3E%3Cpath d='M10.474,12.475a8.231,8.231,0,0,1-3.361,1l.781.769,2.85,2.85c1.042,1.068-.654,2.738-1.707,1.707-.713-.725-1.759-1.77-2.85-2.861L3.337,18.8c-1.053,1.027-2.749-.657-1.7-1.707.725-.725,1.759-1.77,2.85-2.85l.769-.769a8.193,8.193,0,0,1-3.384-1C.659,11.687.125,11.227.6,10.267c.276-.545,1.035-1,2.039-.213a6.059,6.059,0,0,0,3.544,1.079,6.059,6.059,0,0,0,3.544-1.079c1-.788,1.759-.332,2.039.213.463.96-.071,1.419-1.289,2.207ZM1.331,4.844A4.844,4.844,0,1,1,6.176,9.677,4.848,4.848,0,0,1,1.331,4.844Zm2.465,0a2.379,2.379,0,1,0,2.379-2.39A2.383,2.383,0,0,0,3.8,4.844Z' transform='translate(-653.613 -8948)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.telegram {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(514 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-514 -8910)' fill='%233f729b'/%3E%3Cpath d='M11,.563a11,11,0,1,0,11,11A11,11,0,0,0,11,.563ZM16.4,8.1,14.6,16.606c-.133.6-.492.75-.994.466l-2.75-2.027L9.527,16.322a.694.694,0,0,1-.554.271l.2-2.8,5.1-4.6c.222-.2-.049-.306-.342-.111l-6.3,3.965L4.91,12.2c-.59-.186-.6-.59.124-.874L15.64,7.233c.492-.177.923.12.763.865Z' transform='translate(-510 -8906.563)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.telegram:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(514 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-514 -8910)' fill='%233f729b'/%3E%3Cpath d='M11,.563a11,11,0,1,0,11,11A11,11,0,0,0,11,.563ZM16.4,8.1,14.6,16.606c-.133.6-.492.75-.994.466l-2.75-2.027L9.527,16.322a.694.694,0,0,1-.554.271l.2-2.8,5.1-4.6c.222-.2-.049-.306-.342-.111l-6.3,3.965L4.91,12.2c-.59-.186-.6-.59.124-.874L15.64,7.233c.492-.177.923.12.763.865Z' transform='translate(-510 -8906.563)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E"); }

.zen {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(473 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-473 -8910)' fill='none'/%3E%3Cpath d='M30.67,16c-.055,4.459-.36,6.966-2.032,8.638S24.459,26.615,20,26.67v.659c4.459.055,6.966.36,8.638,2.032S30.615,33.541,30.67,38h.659c.055-4.459.36-6.966,2.032-8.638S37.541,27.385,42,27.33V26.67c-4.459-.055-6.966-.36-8.638-2.032S31.385,20.459,31.33,16Z' transform='translate(-489 -8922)' fill='%23000' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .1s ease-in-out; }
.zen:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg transform='translate(473 8910)'%3E%3Crect width='30' height='30' rx='4' transform='translate(-473 -8910)' fill='%23444444'/%3E%3Cpath d='M30.67,16c-.055,4.459-.36,6.966-2.032,8.638S24.459,26.615,20,26.67v.659c4.459.055,6.966.36,8.638,2.032S30.615,33.541,30.67,38h.659c.055-4.459.36-6.966,2.032-8.638S37.541,27.385,42,27.33V26.67c-4.459-.055-6.966-.36-8.638-2.032S31.385,20.459,31.33,16Z' transform='translate(-489 -8922)' fill='%23fff' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E"); }
*/
/*
@font-face {
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ubuntumono/v17/KFOjCneDtsqEr0keqCMhbCc-CsTYl4BOQ3o.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}*/
@font-face {
  font-family: "Gerbera";
  src: local("Gerbera"), url("@/assets/fonts/Gerbera-Black.WOFF") format("woff");
  font-family: "UrbanSlavic";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  src: local("@/assets/fonts/UrbanSlavic.otf"),
    url("@/assets/fonts/UrbanSlavic.otf");
}
</style>
